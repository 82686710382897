import { Box } from '@mui/material';
import axios from 'axios';
import { SimpleForm } from 'react-admin';
import { useSelector } from 'react-redux';
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
import { StepSlider } from './StepSlider';
import { SliderButtons } from './SliderButtons';
import { Button } from '../../../Components/Generics';

const transform = (data) => {
  const formData = data.onboarding?.map((item) => {
    if (!item.description) {
      return {
        ...item,
        description: '',
      };
    }
    return item;
  });
  return { ...data, onboarding: formData };
};

export const OnBoardForm = (props) => {
  const { children, record, stepProps } = props;
  const palette = useSelector((state) => state.palette.palette.primary);

  const customToast = useCustomToasty();
  const handleSubmit = async (formData) => {
    const data = transform(formData);
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Expose-Headers': 'content-range',
      Authorization: 'Bearer ' + localStorage.getItem('auth'),
      // 'x-api-key': process.env.REACT_APP_API_KEY,
    };
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_PATH}/app_config`,
        method: 'PUT',
        headers: headers,
        data: data,
      });
      if (response.data) {
        customToast('success', 'Onboarding updated succesfuly');
        return;
      }
    } catch (error) {
      customToast('error', 'There was an error, please try again later.');
      throw new Error(error);
    }
  };

  const validate = (e) => {
    const { onboarding } = e;
    let actions = [];
    onboarding?.forEach((item) => {
      return actions.push(item.action);
    });
    console.log(actions);
    if (
      !actions.find((item) => item === 'notifications') ||
      !actions.find((item) => item === 'locations')
    ) {
      return customToast(
        'error',
        'The onboarding must contain at least a notification and a locations step.',
      );
    }
    return undefined;
  };

  return (
    <SimpleForm
      onSubmit={handleSubmit}
      record={record}
      disableInvalidFormNotification
      validate={validate}
      reValidateMode="onSubmit"
      toolbar={
        <Box
          id="on_board_provider_toolbar"
          sx={{
            display: 'flex',
            width: '75vw',
            height: '100px',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            style={{
              backgroundColor: palette,
              width: '100px',
              height: '40px',
              color: '#ffffff',
              fontWeight: 600,
            }}
            type="submit"
          >
            Save
          </Button>
        </Box>
      }
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box key="slider-box">
        <StepSlider key="slider-component" {...stepProps} />
      </Box>
      <Box
        key="onboard_provider_box"
        my={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Box
          key="onboard_provider_phone"
          sx={{
            width: '345.6px',
            height: '659.2px',
            outline: '8px solid black',
            borderRadius: '12px',
            position: 'relative',
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        my={2}
        sx={{
          width: '100%',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <SliderButtons {...stepProps} />
      </Box>
    </SimpleForm>
  );
};
