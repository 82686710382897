import { useSelector } from 'react-redux';
import styles from './Dashboard.module.css';
import { useEffect, useState } from 'react';
import { PopularSection } from './PopularSection';
import { InterestsSection } from './InterestsSection';
import {Button} from '../../Components/Generics';

const Dashboard = () => {
  const [tabSelected, setTabSelected] = useState(0);
  const [metrics, setMetrics] = useState();
  const palette = useSelector((state) => state?.palette?.palette);
  const handlerChangeTab = (value) => {
    setTabSelected(value);
  };
  const axiosFunction = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_PATH}/users/metrics`,
        {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Expose-Headers': 'content-range',
            Authorization: 'Bearer ' + localStorage.getItem('auth'),
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data) {
        setMetrics(data);
      }
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };
  useEffect(() => {
    axiosFunction();
  }, []);
  const ratio = Math.round((metrics?.total_users / metrics?.devices) * 100) ;

  return (
    <div className={styles.container}>
      <div className={styles.usersData}>
        <div className={styles.loggedApp}>
          Users logged to app:{' '}
          <span
            className={styles.loggedValue}
            style={{ color: palette.primary }}
          >
            {metrics?.total_users}
          </span>
        </div>
        <div className={styles.loggedRatio}>
          Logged Users/Device Ratio:{' '}
          <span
            className={styles.loggedValue}
            style={{ color: palette.primary }}
          >
            {ratio ? ratio : 0}%
          </span>
        </div>
      </div>
      <div className={styles.btnContainer}>
        <Button
          style={{
            backgroundColor: tabSelected === 0 ? palette?.primary : '#fff',
            color: tabSelected === 0 ? '#fff' : palette?.primary,
            border: `1px solid ${palette?.primary}`,
            borderRadius:'5px',
            width: '240px',
            height: '43px',
            fontSize: '18px',
            fontWeight: 600,
          }}
          onClick={() => handlerChangeTab(0)}
        >
          Popularity
        </Button>
        {/* <Button
          style={{
            backgroundColor: tabSelected === 1 ? palette?.primary : '#fff',
            color: tabSelected === 1 ? '#fff' : palette?.primary,
            border: `1px solid ${palette?.primary}`,
            borderRadius:'5px',
            width: '240px',
            height: '43px',
            fontSize: '18px',
            fontWeight: 600,
          }}
          onClick={() => handlerChangeTab(1)}
          disabled={true}
        >
          Interests
        </Button> */}
      </div>
      {tabSelected === 0 ? (
        <PopularSection
          posts={metrics?.articles}
          events={metrics?.events}
          talents={metrics?.talents}
        />
      ) : (
        <InterestsSection />
      )}
    </div>
  );
};

export default Dashboard;