import { useInput, useRecordContext } from 'react-admin';
import { InputAddTextInputs } from '../../../Talents/Form/TalentsListComponents/InputAddTextInputs';
import { LinksItems } from './LinksItems';
import { useFormContext } from 'react-hook-form';
import { memo, useCallback, useMemo } from 'react';

const InputAddLinkItems = (props) => {
  const { source } = props;
  const {
    field: { value, onChange },
  } = useInput({ source, defaultValue: [] });
  
  const record = useRecordContext();
  const handleDelete = (index) => {
    let newArr = value?.filter((_, i) => i !== index);
    onChange(newArr);
  };
  const objKey = useCallback((value, str) => {
    let split = str.split('.');
  
    if(str.includes('.')){
      let newString = split.filter((item, index) => index !== 0).join('.');
  
      return objKey(value[split[0]], newString.toString());
    }
    return value[str];
  }, [props]);


  const propsComponent = useMemo(() => {
    let initialNumber = objKey(record, source).length;

    let obj =  {
      key: `inputaddlinks-${source}`,
      direction: 'row',
      addBelow: true,
      onDelete: handleDelete,
      element: <LinksItems {...props} />,
      initialNumber: initialNumber,
      limit: 10,
      ...props
    };
    return obj;
  },[record, props]);

  const MemoComponent = useMemo(() => {
    return InputAddTextInputs;
  }, [record, props]);

  return (
    <MemoComponent {...propsComponent} />
  );
};
export default memo(InputAddLinkItems);
