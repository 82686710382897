
import { useInput, useRecordContext } from 'react-admin';

import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useUtcFormat } from '../../../helpers/date';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { InputTitle } from '../../StyledComponents/InputTitle';
import InputTimePicker from '../InputTimePicker';
import { extractDatesFromString } from '../../../helpers/inputErrorMessage';
import { switchStyleIos } from '../../StyledComponents/Inputs';
import { checkCloseDate, handleHours } from './functions';



export const TimeLineRow = ({
  dayPicked,
  index,
  newValues,
  handleChange,
  error,
  source,
  open24
}) => {

  const defaultTime = dayjs(dayPicked).format('YYYY-MM-DDTHH:mm:ss');
  const [nextDay, setNextDay] = useState(newValues ? checkCloseDate(newValues[index]?.value, dayPicked) : false);

  const [times, setTimes] = useState( 
    newValues[index]?.value ?? 
    {
      open: defaultTime, 
      close: defaultTime
    });

  useEffect(() => {
    let firstHour = dayjs(dayPicked).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    let lastHour = dayjs(dayPicked).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    if(newValues[index]?.value?.open === firstHour && newValues[index]?.value?.close === lastHour){
      setNextDay(false);
    } 
    let updatedTimes = handleHours(open24, dayPicked, times);
    
    setTimes(updatedTimes);
    return() => {
      setTimes(newValues[index]?.value ?? 
        {
          open: defaultTime, 
          close: defaultTime
        });
      setNextDay(newValues ? checkCloseDate(newValues[index]?.value, dayPicked) : false);
    };
  }, [open24]);

  useEffect(() => {
    let valueUpdated = false;
    const updatedValues = newValues.map((item) => {
      if (item.key === index) {
        valueUpdated = true;
        return { key: item.key, value: times };
      } 
      return item;
    });
    if (!valueUpdated) {
      updatedValues.push({ key: index, value: times });
    }
    
    handleChange(updatedValues);
    return() => {
      valueUpdated = false;
    };
    // }
  },[open24, times]);

  const handleNextDay = (e, time) => {
    let newTime = time ? time : times.close;
    setNextDay(e);
    if(e) {
      return setTimes({...times, close: dayjs(newTime).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')});
    } else {
      return setTimes({...times, close: dayjs(newTime).subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss')});
    }
  };

  const closeTimeLabel = useMemo(() => {
    return `${dayjs(dayPicked).add(nextDay ? 1 : 0, 'days').format('MMMM D YYYY')} - Close Time`;
  },[nextDay, dayPicked, open24]);

  return(
    <div style={{width: '100%'}}>
      <div style={{display:'flex',flexDirection:'row',gap:10,  width: '100%'}}>
        <div style={{ width: '100%'}}>
          <InputTitle sx={{marginLeft:'10px',marginBottom:'10px'}}>
              OPEN TIME
          </InputTitle>
          <InputTimePicker
            row={dayPicked}
            field="open"
            onChange={setTimes}
            value={times}
            defaultValue={dayjs(dayPicked)}
            disabled={open24}
            variant="outlined"
            error={extractDatesFromString(error) ? extractDatesFromString(error)?.[0] : false}
            // minTimeSet={minTimeSet}
            label={`${dayjs(dayPicked).format('MMMM D YYYY')} - Open Time` }
            FormHelperTextProps={{ sx: {
              display: 'none'
            }} }
            sx={{
              width: '100%',
              maxWidth: '90%'
            }}
            ampm={false}
          />
        </div>
        <div style={{ width: '100%'}}>
          <InputTitle sx={{marginLeft:'10px',marginBottom:'10px'}}>
              CLOSE TIME
          </InputTitle>
          <InputTimePicker
            row={dayPicked}
            field="close"
            onChange={setTimes}
            value={times}
            minTime={!nextDay ? dayjs(times.open) : dayjs(times.open).add(1, 'days').startOf('day')}
            maxTime={nextDay ? dayjs(dayPicked).add(7, 'hours') : false}
            defaultValue={dayjs(dayPicked)}
            disabled={open24}
            handleMinTime={handleNextDay}
            variant="outlined"
            label={closeTimeLabel}
            setNextDay={setNextDay}
            endsNextDay={nextDay}
            ampm={false}
            error={extractDatesFromString(error) ? extractDatesFromString(error)?.[1] : false}
            sx={{
              width: '100%',
              maxWidth: '90%'
            }}
          />
        </div>
      </div>
      <FormGroup sx={{display:'flex',flexDirection:'row',marginLeft:'10px',gap:20, width: '100%', marginBottom: '20px'}}>
        <div style={{display:'flex',flexDirection:'row',alignItems: 'center'}}>
          <FormControlLabel
            control={
              <Switch
                checked={nextDay} 
                sx={switchStyleIos}
                disabled={open24}
                onChange={(e) => {handleNextDay(e.target.checked);}}
              />
            }
          />
          <Typography>Ends next day</Typography>
        </div>
            
      </FormGroup>
            
    </div>
  );
};