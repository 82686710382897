import { required, useResourceContext } from 'react-admin';
import { useSelector } from 'react-redux';
import { baseInputsNotRequired } from '../Resources/Info/Form/FormInfoSections/schemaExceptions';
import { EXCLUDED_SCHEMAS } from '../Components/AdaptableForm/SchemaGridSection';


export const useRequiredSchema = () => {
  const resource = useResourceContext();
  const baseAndRequired = useSelector((state) => {
    const schema = state.data.schemas[resource];
    
    if(schema && !EXCLUDED_SCHEMAS.includes(resource)) {
      return [...schema.required, ...schema.base];
    }
    return [];
  });

  function isRequiredSchema(source, message) {
    if (baseAndRequired.includes(source) && !baseInputsNotRequired.includes(source)) {
      return [required(message ?? `The ${source} field is required`)];
    }
    return [];
  }

  return {
    isRequiredSchema,
  };
};