//Material & Icons
import  InputAddLinkItems  from '../Components/InputAddLinkItems.jsx';

export const QuickLinksForm = () => {
  const data = [
    {
      inputs: [
        {
          field: (
            <InputAddLinkItems
              source="home.quicklinks.items"
              noDisableRemove={true}
            />
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];

  return {
    data: data,
  };
};
